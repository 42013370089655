


































































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import cancelSaveDialog from '@/mixins/cancelSaveDialog'
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapState, mapGetters } from 'vuex'
import { RawLocation } from 'vue-router'
import { isReadonly } from '@/composables/UserGrant'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
    }
  },
  name: 'Profession',
  components: {
    TopBar,
    CancelUpdateDialog: () => import('@/components/cancel-update-dialog.vue'),
    TimeField: () => import('@/components/base/TimeField.vue'),
    TimeSlots: () => import('@/components/molecules/timeSlots.vue'),
    TeachingAids: () => import('@/components/molecules/input/teachingAids.vue'),
  },
  data () {
    return {
      title: this.$t('profession.form.title'),
      errors: [],
      isLoading: true,
    }
  },
  mixins: [cancelSaveDialog],
  computed: {
    ...mapFields('professionForm', [
      'profession.label',
      'profession.isActive',
    ]),
    ...mapState('professionForm', {
      isProfessionSubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
    }),
    ...mapGetters('navigation', {
      getLastSavedRoute: 'getLastSavedRoute',
    }),
    idProfession () {
      return this.$route.params.idProfession || null
    },
  },
  async created () {
    await this.init()
  },
  methods: {
    async init () {
      if (this.idProfession) {
        await this.loadProfessionById(
          this.idProfession,
        )
        this.isLoading = false
      } else {
        this.reset()
        this.isLoading = false
      }
    },
    ...mapActions('profession', {
      loadAllProfessions: 'load',
    }),
    ...mapActions('professionForm', {
      save: 'save',
      loadProfessionById: 'loadById',
      reset: 'reset',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(
        this.$refs.validationObserver,
      )
      if (isValidatedForm) {
        await this.save(this.profession)
        this.loadAllProfessions({
          pagination: false,
        })
        await this.sendSuccessMessage('profession.form.saved')
        this.returnPrevious()
      }
    },
    cancel () {
      this.returnPrevious()
    },
    returnPrevious () {
      if (this.getLastSavedRoute) {
        this.$router.push(this.getLastSavedRoute as RawLocation)
      } else {
        this.$router.push({ name: 'Profession List' })
      }
    },
  },
})
